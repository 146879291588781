import Cookie from 'js-cookie';
import Choices from "choices.js";

class LanguageModal {
    constructor(selector) {
        this.selector = selector;
        this.body = document.body;
        this.close_buttons = this.selector.querySelectorAll('[data-element="close"]');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.links = window.lang_links;
        this.region_select = this.body.querySelector('[data-element="region"]');
        this.language_select = this.body.querySelector('[data-element="language"]');
        this.button = this.body.querySelector('[data-element="button"]');

        this.lang_select = new Choices(this.language_select, {
            placeholder: true,
            placeholderValue: 'Select your Language'
        });

        this._clickEvent = this._clickEvent.bind(this);

        if (this.region_select !== null) {
            console.log(this.region_select);
            this._changeRegion = this._changeRegion.bind(this);
            this.region_select.addEventListener('change', this._changeRegion);
        }

        if (this.button !== null) {
            this._clickButton = this._clickButton.bind(this);
            this.button.addEventListener('click', this._clickButton);
        }

        if (this.close_buttons !== null) {
            this.close_buttons.forEach(btn => {
                btn.addEventListener('click', this._clickEvent);
            });
        }

        setTimeout(() => {
            if (Cookie.get('lnm_cookie') === undefined) {
                this.body.classList.add('overlay');
                this.selector.classList.add('modal--shown');
            }

            // let specifiedElement = this.selector;
            // document.addEventListener('click', function(e) {
            //     let isClickInside = specifiedElement.contains(e.target);
            //
            //     if (!isClickInside) {
            //         document.body.classList.remove('overlay');
            //         specifiedElement.classList.remove('modal--shown');
            //         Cookie.set('lnm_cookie', true, { expires: 365 });
            //     }
            // });
        }, 500);
        // }
    }
    _changeRegion(e) {
        let value = this.region_select.value;
        let ob = this;
        console.log(value);
        this.lang_select.destroy();
        this.language_select.innerHTML = '';
        let option = document.createElement('option');
        option.setAttribute('value', '');
        option.setAttribute('placeholder', '');
        option.innerText = 'Select your Language';
        this.language_select.append(option);
        if (value !== '') {
            if (this.links !== null) {
                let langs = this.links[value].languages;
                Object.keys(langs).forEach(function (obj) {
                    let tmp = langs[obj];
                    let option_l = document.createElement('option');
                    option_l.setAttribute('value', tmp.url);
                    option_l.innerText = tmp.name;
                    ob.language_select.append(option_l);
                });
            }
        }
        this.lang_select = new Choices(this.language_select, {
            placeholder: true,
            placeholderValue: 'Select your Language'
        });
    }
    _clickButton(e) {
        e.preventDefault();

        let url = this.language_select.value;
        if (url !== '') {
            window.location.href = url;
            Cookie.set('lnm_cookie', true, { expires: 365 });
        }
    }
    _clickEvent(e) {
        e.preventDefault();

        if (this.body.classList.contains('overlay')) {
            this.body.classList.remove('overlay');
        }

        if (this.selector.classList.contains('modal--shown')) {
            this.selector.classList.remove('modal--shown');
        }
        Cookie.set('lnm_cookie', true, { expires: 365 });
    }
}
export default LanguageModal;