
class Tabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelectorAll('[data-element="tab"]');
        this.tabsContainer = this.selector.querySelector('[data-element="tabs-container"]');

        this._tabClick = this._tabClick.bind(this);

        this.tabs.forEach((tab) => {
            tab.addEventListener('click', this._tabClick);
        })
    }
    _tabClick(event) {
        event.preventDefault();
        let tab = event.target.getAttribute('href');
        let contents = this.tabsContainer.querySelectorAll('[data-tab]');
        this.tabs.forEach(t => {
            t.classList.remove('summercamp__tab--active');
        })
        event.target.classList.add('summercamp__tab--active');
        contents.forEach((content) => {
            let id = content.getAttribute('data-tab');
            if(id === tab) {
                content.style.display = 'block';
            } else {
                content.style.display = 'none';
            }
        });
    }
}

export default Tabs;